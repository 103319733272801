// for ie11
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import { store } from './stores';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './assets/styles/index.css';

const Index = () => {
  return (
    <StoreProvider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </StoreProvider >
  )
}

ReactDOM.render(<Index />, document.getElementById('root'));


let script = document.createElement('script');
if (window.location.hostname === 'epay2.marstree.ltd') {
  script.src = 'https://gpmac.gateway.mastercard.com/static/checkout/checkout.min.js';
} else {
  script.src = 'https://ap-gateway.mastercard.com/checkout/version/61/checkout.js';
}
script.setAttribute("data-error", "mpgs.errorCallback");
script.setAttribute("data-cancel", "mpgs.cancelCallback");

document.querySelector('body').appendChild(script);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
