//language
import TChinese from "../langs/zh-TW.json";
import SChinese from "../langs/zh-CN.json";
import English from "../langs/english.json";

const actionTypes = {
  LanguageLoaded: "[ACTION] language loaded",
};

export const actions = {
  fillLanguage: (locale) => ({
    type: actionTypes.LanguageLoaded,
    payload: { locale }
  })
};

const locale = window.navigator.userLanguage || window.navigator.language;

const initLanguageState = {
  "locale": locale,
  "message": (locale === 'zh-TW') ? TChinese : (locale === 'zh-CN') ? SChinese : English
};

export const reducer = (state = initLanguageState, action) => {
  switch (action.type) {

    case actionTypes.LanguageLoaded: {
      const { locale } = action.payload;
      return {
        ...state,
        locale: locale,
        message: (locale === 'zh-TW') ? TChinese : (locale === 'zh-CN') ? SChinese : English
      };
    }

    default: {
      return state;
    }
  }
}