import axios from "axios";
import * as config from "../configs/";

export const add = async (params, clientCredential) => {
  const newParams = {
    ...params,
    'X-API-KEY': clientCredential
  }
  try {
    const resp = await axios.post(config.api.url + 'card/data?', {
      ...newParams
    });
    return resp;
  } catch (error) {
    console.warn("card ERROR: ", error);
    return false;
  }
}
