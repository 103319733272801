import React, { useEffect, useState } from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import {
  Box,
  Container,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent
} from "@mui/material";
import moment from "moment";
import { connect, useSelector } from "react-redux";
//stores
import * as websiteRedux from "./stores/websiteRedux";
import * as saleOrderRedux from "./stores/saleOrderRedux";

//models
import * as websiteModel from "./models/websiteModel";
import * as saleOrderModel from "./models/saleOrderModel";
//screen
import PaymentScreen from "./screens/PaymentScreen";

const App = (props) => {

  const queryParams = new URLSearchParams(window.location.search);
  const [clientToken] = useState(queryParams.get('token'));
  const [txnCode] = useState(queryParams.get('txnCode'));

  const language = useSelector(({ language }) => language);
  const saleOrder = useSelector(({ saleOrder }) => saleOrder);

  useEffect(() => {
    getWebsite();
    getSaleOrder();
    // eslint-disable-next-line
  }, []);

  const getWebsite = async () => {
    if (!!!clientToken) return;
    const resp = await websiteModel.getOne(clientToken);
    if (!!resp?.data) props.fillWebsite(resp.data);
  }

  const getSaleOrder = async () => {
    if (!!!txnCode || !!!clientToken) return;
    const resp = await saleOrderModel.getOne({
      code: txnCode
    }, clientToken);
    if (!!resp?.data) props.fillSaleOrder(resp.data);
  }

  return (
    <IntlProvider locale={language?.locale} messages={language?.message} defaultLocale="en">
      {(!!!clientToken || !!!txnCode) ? (
        <Dialog
          open={true}
          onClose={() => { }}
          keepMounted
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Notice</DialogTitle>
          <Divider />
          <DialogContent>
            <FormattedMessage id="please_contact_admin" />
          </DialogContent>
        </Dialog>


      ) : ((saleOrder && saleOrder?.status && saleOrder?.status !== 'CREATE') ? (
        <Dialog
          open={true}
          onClose={() => { }}
          keepMounted
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Notice</DialogTitle>
          <Divider />
          <DialogContent>
            <FormattedMessage id="transaction_done" />
          </DialogContent>
        </Dialog>

      ) : ((saleOrder && saleOrder?.created_at && (+saleOrder?.created_at + 300) < +moment().format('X')) && (
        <Dialog
          open={true}
          onClose={() => { }}
          keepMounted
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Notice</DialogTitle>
          <Divider />
          <DialogContent>
            <FormattedMessage id="transaction_timeout" />
          </DialogContent>
        </Dialog>

      )))
      }
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Container maxWidth="sm" sx={{ background: '#ffffff', minHeight: 480 }} >
          {(saleOrder && saleOrder?.status && saleOrder?.status === 'CREATE' && (+saleOrder?.created_at + 300) >= +moment().format('X')) && (
            <PaymentScreen />
          )}
        </Container>
      </Box>
    </IntlProvider>
  )
}

export default connect(null, {
  ...websiteRedux.actions,
  ...saleOrderRedux.actions
})(App);
