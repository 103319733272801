const actionTypes = {
  SaleOrderLoaded: "[ACTION] SaleOrder set loaded"
};

export const actions = {
  fillSaleOrder: (sale_oder) => ({
    type: actionTypes.SaleOrderLoaded,
    payload: { sale_oder }
  })
};


const initSaleOrder = {
  "id": undefined,
  "code": undefined,
  "total_amount": undefined,
  "status": undefined,
  "created_at": undefined
};

export const reducer = (state = initSaleOrder, action) => {
  switch (action.type) {

    case actionTypes.SaleOrderLoaded: {
      const { sale_oder } = action.payload;
      return {
        ...state, ...sale_oder
      }
    }

    default: {
      return state;
    }
  }
}