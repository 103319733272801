const actionTypes = {
  WebsiteLoaded: "[ACTION] Website set loaded"
};

export const actions = {
  fillWebsite: (website) => ({
    type: actionTypes.WebsiteLoaded,
    payload: { website }
  })
};


const initWebsite = {
  "id": undefined,
  "site_name": undefined,
  "s3_folder": undefined,
  "website_setting": {}
};

export const reducer = (state = initWebsite, action) => {
  switch (action.type) {

    case actionTypes.WebsiteLoaded: {
      const { website } = action.payload;
      return {
        ...state, ...website
      }
    }

    default: {
      return state;
    }
  }
}