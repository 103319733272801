//@ts-check
import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

//load redux file;
import * as token from './tokenRedux';
import * as language from "./languageRedux";
import * as website from './websiteRedux';
import * as saleOrder from './saleOrderRedux';
import * as payment from './paymentRedux';


const rootReducer = combineReducers({
  token: token.reducer,
  language: language.reducer,
  website: website.reducer,
  saleOrder: saleOrder.reducer,
  payment: payment.reducer
});

export default rootReducer;