import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect, useSelector } from "react-redux";
import { Box } from "@mui/material";
import URL from "url";

//configs
import * as config from "../configs";
//stores
import * as websiteRedux from "../stores/websiteRedux";
import * as paymentRedux from "../stores/paymentRedux";
//models
// import * as websiteModel from "../models/websiteModel";
import * as saleOrderModel from "../models/saleOrderModel";
//components
import LangMenu from "../components/LangMenu";
import SelectPayChannel from "../components/SelectPayChannel";
import CybersourceDialog from "../components/CybersourceDialog";

// icon
import VisaGP from "../assets/svg/visa_gp.jpg";
import MastercardGP from "../assets/svg/mastercard_gp.gif";
import JCBGP from "../assets/svg/jcb.jpg";

const PaymentScreen = (props) => {
  const queryParams = new URLSearchParams(window.location.search);

  const [clientToken] = useState(queryParams.get("token"));
  const [txnCode] = useState(queryParams.get("txnCode"));
  const [openCybersourceDialog, setOpenCybersourceDialog] = useState(false);

  const payment = useSelector(({ payment }) => payment);
  const saleOrder = useSelector(({ saleOrder }) => saleOrder);
  const websiteSetting = useSelector(({ website }) => website).website_setting;

  useEffect(() => {
    if (clientToken && txnCode) {
      if (payment?.step !== 2) return;
      const payChannel = payment?.pay_channel;
      // eslint-disable-next-line
      switch (payChannel) {
        case "boc_mpgs":
          sendTranscationMPGS();
          break;
        case "boc_cybersource":
          setOpenCybersourceDialog(true);
          break;
        case "boc_upop":
        case "boc_vm":
        case "boc_bocpay":
        case "boc_mpay":
        case "boc_alipay":
        case "boc_wechatpay":
        case "boc_icbcepay":
        case "boc_taifungpay":
        case "boc_unionpayqr":
        case "mpay":
        case "mpay_alipay":
        case "uepay":
          const url =
            config.api.url +
            "sale_order/pay?code=" +
            txnCode +
            "&payChannel=" +
            payment.pay_channel +
            "&X-API-KEY=" +
            clientToken;
          console.log("url", url);
          window.top.location.href = url;
          break;
      }
    }

    // eslint-disable-next-line
  }, [payment, clientToken]);

  const sendTranscationMPGS = async () => {
    if (!!!clientToken || !!!txnCode) return false;
    const resp = await saleOrderModel.pay(
      {
        code: txnCode,
        payChannel: payment.pay_channel,
      },
      clientToken,
    );

    if (!resp?.data?.session_id) return false;

    const configure = {
      session: {
        id: resp.data.session_id,
      },
      interaction: {
        merchant: {
          name: websiteSetting?.boc?.mpgs?.merchant_name,
        },
      },
    };

    window.Checkout.configure(configure);
    const url = URL.parse(websiteSetting?.boc?.mpgs?.server_url || "");
    if (url.hostname === "gpmac.gateway.mastercard.com") {
      window.Checkout.showPaymentPage();
    } else {
      window.Checkout.showLightbox();
    }
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleCybersourceDialogClose = () => {
    props.prevStep();
    setOpenCybersourceDialog(false);
  };

  return (
    <div className="payment-app">
      <div className="navigation">
        <LangMenu />
      </div>

      {(payment?.step === 1 || payment?.pay_channel === "boc_cybersource") && (
        <>
          <div style={{ padding: 16 }}>
            <div className="title">
              <FormattedMessage id="total_amount" />
            </div>
            <h1 className="total">
              <span className="currency">MOP$</span>
              {numberWithCommas(
                parseFloat(
                  +(saleOrder?.total_amount ? saleOrder?.total_amount : 0) /
                    100,
                ).toFixed(2),
              )}
            </h1>
            <hr
              style={{
                borderTop: "1px solid #d8d8d8",
                borderBottom: 0,
              }}
            />
          </div>

          <SelectPayChannel />
        </>
      )}

      <CybersourceDialog
        open={openCybersourceDialog}
        onClose={handleCybersourceDialogClose}
      />

      {window.location.hostname === "epay2.marstree.ltd" && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 16,
          }}
        >
          <img src={VisaGP} alt="visa" style={{ height: 32 }} />
          <img src={MastercardGP} alt="mastercard" style={{ height: 32 }} />
          <img src={JCBGP} alt="jcb" style={{ height: 32 }} />
        </Box>
      )}
    </div>
  );
};

export default connect(null, {
  ...websiteRedux.actions,
  ...paymentRedux.actions,
})(PaymentScreen);

