import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useFormik } from "formik";
import { connect, useSelector } from "react-redux";
//stores
import * as paymentRedux from "../stores/paymentRedux";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import * as Yup from "yup";

//svg mpay
import MPay from "../assets/svg/mpay.svg";
import Alipay from "../assets/svg/alipay.svg";
import Wechatpay from "../assets/svg/wechatpay.svg";
import Visa from "../assets/svg/visa.svg";
import Mastercard from "../assets/svg/mastercard.svg";
import Upop from "../assets/svg/unionpay.svg";
import Bocpay from "../assets/svg/bocpay.svg";
import Taifungpay from "../assets/svg/taifungpay.svg";
import Icbcpay from "../assets/svg/icbcpay.svg";
import UEPay from "../assets/svg/uepay.svg";
import UnionpayQR from "../assets/svg/unionpayqr.svg";
import JCBGP from "../assets/svg/jcb.jpg";

const initFormValues = {
  pay_channel: undefined,
};

const SelectPayChannel = (props) => {
  const websiteSetting = useSelector(({ website }) => website).website_setting;
  const classes = labelStyles();

  const cancelReturn = () => {
    const queryParams = new URLSearchParams(window.location.search);
    window.location.href = decodeURIComponent(queryParams.get("cancelUrl"));
  };

  // eslint-disable-next-line
  const [, setLoading] = useState(false);
  const payChannelSchema = Yup.object().shape({
    pay_channel: Yup.string().required("必填"),
  });

  const formik = useFormik({
    initialValues: initFormValues,
    validationSchema: payChannelSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      const { pay_channel } = values;
      props.fillPayChannel(pay_channel);
      props.nextStep();
      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="form-select-paychannel">
      <FormControl component="fieldset">
        <FormLabel component="legend">
          <FormattedMessage id="please_choose_payment_method" />
        </FormLabel>
        {formik.status && <FormHelperText>{formik.status}</FormHelperText>}
        <RadioGroup
          aria-label="pay_channel"
          name="pay_channel"
          onChange={formik.handleChange}
          value={formik?.values?.pay_channel}
          {...formik.getFieldProps("pay_channel")}
        >
          {/* BOCMACAU MPGS START*/}
          {websiteSetting?.boc?.enable + "" === "1" &&
            websiteSetting?.boc?.mpgs?.enable + "" === "1" && (
              <FormControlLabel
                value="boc_mpgs"
                control={<MyRadio name="pay_channel" value="boc_mpgs" />}
                labelPlacement="start"
                className={classes.root}
                label={
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: "#E94619" }}>
                        <CreditCardIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          <FormattedMessage id="credit_card_title" />
                          <img
                            src={Visa}
                            style={{
                              marginLeft: 8,
                              height: 10,
                            }}
                            alt="Visa"
                          />
                          <img
                            src={Mastercard}
                            style={{
                              marginLeft: 8,
                              height: 12,
                            }}
                            alt="Mastercard"
                          />
                          {window.location.hostname ===
                            "epay2.marstree.ltd" && (
                            <img
                              src={JCBGP}
                              style={{
                                height: 14,
                                marginLeft: 8,
                              }}
                              alt="JCB"
                            />
                          )}
                        </>
                      }
                      secondary={
                        window.location.hostname === "epay2.marstree.ltd" ? (
                          <FormattedMessage id="credit_card_desc_gp" />
                        ) : (
                          <FormattedMessage id="credit_card_desc" />
                        )
                      }
                    />
                  </ListItem>
                }
              />
            )}
          {/* BOCMACAU MPGS END*/}
          {/* BOCMACAU Cybersource START*/}
          {websiteSetting?.boc?.enable + "" === "1" &&
            websiteSetting?.boc?.cybersource?.enable + "" === "1" && (
              <FormControlLabel
                value="boc_cybersource"
                control={<MyRadio name="pay_channel" value="boc_cybersource" />}
                labelPlacement="start"
                className={classes.root}
                label={
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: "#E94619" }}>
                        <CreditCardIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          <FormattedMessage id="credit_card_title" />
                          <img
                            src={Visa}
                            style={{
                              marginLeft: 8,
                              height: 10,
                            }}
                            alt="Visa"
                          />
                          <img
                            src={Mastercard}
                            style={{
                              marginLeft: 8,
                              height: 12,
                            }}
                            alt="Mastercard"
                          />
                        </>
                      }
                      secondary={<FormattedMessage id="credit_card_desc" />}
                    />
                  </ListItem>
                }
              />
            )}
          {/* BOCMACAU Cybersource END*/}
          {/* BOCMACAU VM START*/}
          {websiteSetting?.boc?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.vm + "" === "1" && (
              <FormControlLabel
                value="boc_vm"
                control={<MyRadio name="pay_channel" value="boc_vm" />}
                labelPlacement="start"
                className={classes.root}
                label={
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: "#E94619" }}>
                        <CreditCardIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          <FormattedMessage id="credit_card_title" />
                          <img
                            src={Visa}
                            style={{
                              marginLeft: 8,
                              height: 10,
                            }}
                            alt="Visa"
                          />
                          <img
                            src={Mastercard}
                            style={{
                              marginLeft: 8,
                              height: 12,
                            }}
                            alt="Mastercard"
                          />
                          <img
                            src={Upop}
                            style={{
                              marginLeft: 8,
                              height: 12,
                            }}
                            alt="UPOP"
                          />
                        </>
                      }
                      secondary={<FormattedMessage id="credit_card_desc" />}
                    />
                  </ListItem>
                }
              />
            )}
          {/* BOCMACAU VM END*/}
          {/* BOCMACAU UNIONPAYQR START*/}
          {websiteSetting?.boc?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.unionpayqr + "" === "1" && (
              <FormControlLabel
                value="boc_unionpayqr"
                control={<MyRadio name="pay_channel" value="boc_unionpayqr" />}
                labelPlacement="start"
                className={classes.root}
                label={
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: "#f0f0f0" }}>
                        <img
                          src={UnionpayQR}
                          alt="銀聯雲閃付"
                          style={{
                            width: "66%",
                          }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<FormattedMessage id="unionpayqr_title" />}
                      secondary={<FormattedMessage id="unionpayqr_desc" />}
                    />
                  </ListItem>
                }
              />
            )}
          {/* BOCMACAU UNIONPAYQR END*/}
          {/* BOCMACAU BOCPay START*/}
          {websiteSetting?.boc?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.bocpay + "" === "1" && (
              <FormControlLabel
                value="boc_bocpay"
                control={<MyRadio name="pay_channel" value="boc_bocpay" />}
                labelPlacement="start"
                className={classes.root}
                label={
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: "rgb(161,0,43)" }}>
                        <img
                          src={Bocpay}
                          style={{ width: "60%" }}
                          alt="Bocpay"
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<FormattedMessage id="bocpay_title" />}
                      secondary={<FormattedMessage id="bocpay_desc" />}
                    />
                  </ListItem>
                }
              />
            )}
          {/* BOCMACAU BOCpay END*/}
          {/* BOCMACAU Mpay START */}
          {websiteSetting?.boc?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.mpay + "" === "1" && (
              <FormControlLabel
                value="boc_mpay"
                control={<MyRadio name="pay_channel" value="boc_mpay" />}
                labelPlacement="start"
                className={classes.root}
                label={
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: "#FF8300" }}>
                        <img src={MPay} style={{ width: "50%" }} alt="MPay" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<FormattedMessage id="mpay_title" />}
                      secondary={<FormattedMessage id="mpay_desc" />}
                    />
                  </ListItem>
                }
              />
            )}
          {/* BOCMACAU Mpay END */}
          {/* BOCMACAU Alipay START */}
          {websiteSetting?.boc?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.alipay + "" === "1" && (
              <FormControlLabel
                value="boc_alipay"
                control={<MyRadio name="pay_channel" value="boc_alipay" />}
                labelPlacement="start"
                className={classes.root}
                label={
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: "#00A0E9" }}>
                        <img
                          src={Alipay}
                          alt="支付寶"
                          style={{ width: "75%" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<FormattedMessage id="alipay_title" />}
                      secondary={
                        <>
                          <FormattedMessage id="alipay_desc" />
                          <br />
                          <FormattedMessage id="boc_alipay_desc2" />
                        </>
                      }
                    />
                  </ListItem>
                }
              />
            )}
          {/* BOCMACAU Alipay END */}

          {/* BOCMACAU Wechatpay START */}
          {websiteSetting?.boc?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.wechatpay + "" === "1" && (
              <FormControlLabel
                value="boc_wechatpay"
                control={<MyRadio name="pay_channel" value="boc_wechatpay" />}
                labelPlacement="start"
                className={classes.root}
                label={
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: "#FFF" }}>
                        <img
                          src={Wechatpay}
                          alt="微信支付"
                          style={{ width: "100%" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<FormattedMessage id="wechatpay_title" />}
                      secondary={
                        <>
                          <FormattedMessage id="wechatpay_desc" />
                          <br />
                          <FormattedMessage id="boc_wechatpay_desc2" />
                        </>
                      }
                    />
                  </ListItem>
                }
              />
            )}
          {/* BOCMACAU Wechatpay END */}

          {/* BOCMACAU Taifungpay START */}
          {websiteSetting?.boc?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.taifungpay + "" === "1" && (
              <FormControlLabel
                value="boc_taifungpay"
                control={<MyRadio name="pay_channel" value="boc_taifungpay" />}
                labelPlacement="start"
                className={classes.root}
                label={
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: "#FFF" }}>
                        <img
                          src={Taifungpay}
                          alt="豐付寶"
                          style={{ width: "100%" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<FormattedMessage id="taifungpay_title" />}
                      secondary={
                        <>
                          <FormattedMessage id="taifungpay_desc" />
                        </>
                      }
                    />
                  </ListItem>
                }
              />
            )}
          {/* BOCMACAU Taifungpay END */}

          {/* BOCMACAU ICBCepay START */}
          {websiteSetting?.boc?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.enable + "" === "1" &&
            websiteSetting?.boc?.bocpay?.icbcepay + "" === "1" && (
              <FormControlLabel
                value="boc_icbcepay"
                control={<MyRadio name="pay_channel" value="boc_icbcepay" />}
                labelPlacement="start"
                className={classes.root}
                label={
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: "rgb(161,0,43)" }}>
                        <img
                          src={Icbcpay}
                          style={{ width: "60%" }}
                          alt="ICBC pay"
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<FormattedMessage id="icbcepay_title" />}
                      secondary={
                        <>
                          <FormattedMessage id="icbcepay_desc" />
                        </>
                      }
                    />
                  </ListItem>
                }
              />
            )}
          {/* BOCMACAU ICBCepay END */}

          {/* BOCMACAU UPOP START*/}
          {websiteSetting?.boc?.enable + "" === "1" &&
            websiteSetting?.boc?.upop?.enable + "" === "1" && (
              <FormControlLabel
                value="boc_upop"
                control={<MyRadio name="pay_channel" value="boc_upop" />}
                labelPlacement="start"
                className={classes.root}
                label={
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: "#f0f0f0" }}>
                        <img
                          src={Upop}
                          alt="銀聯卡"
                          style={{
                            width: "66%",
                          }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<FormattedMessage id="unionpay_title" />}
                      secondary={<FormattedMessage id="unionpay_desc" />}
                    />
                  </ListItem>
                }
              />
            )}
          {/* BOCMACAU MPGS END*/}

          {/* Mpay START*/}
          {websiteSetting?.mpay?.enable + "" === "1" && (
            <FormControlLabel
              value="mpay"
              control={<MyRadio name="pay_channel" value="mpay" />}
              labelPlacement="start"
              className={classes.root}
              label={
                <ListItem>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#FF8300" }}>
                      <img src={MPay} style={{ width: "50%" }} alt="MPay" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<FormattedMessage id="mpay_title" />}
                    secondary={<FormattedMessage id="mpay_desc" />}
                  />
                </ListItem>
              }
            />
          )}
          {/* Mpay END*/}

          {/* Mpay Alipay START*/}
          {websiteSetting?.mpay?.alipay?.enable + "" === "1" && (
            <FormControlLabel
              value="mpay_alipay"
              control={<MyRadio name="pay_channel" value="mpay_alipay" />}
              labelPlacement="start"
              className={classes.root}
              label={
                <ListItem>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#00A0E9" }}>
                      <img src={Alipay} alt="支付寶" style={{ width: "75%" }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<FormattedMessage id="alipay_title" />}
                    secondary={
                      <>
                        <FormattedMessage id="alipay_desc" />
                        <br />
                        <FormattedMessage id="alipay_desc2" />
                      </>
                    }
                  />
                </ListItem>
              }
            />
          )}
          {/* Mpay Alipay END*/}

          {/* Uepay START*/}
          {websiteSetting?.uepay?.enable + "" === "1" && (
            <FormControlLabel
              value="uepay"
              control={<MyRadio name="pay_channel" value="uepay" />}
              labelPlacement="start"
              className={classes.root}
              label={
                <ListItem>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "rgb(13, 173, 221)" }}>
                      <img src={UEPay} style={{ width: "50%" }} alt="UEPay" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<FormattedMessage id="uepay_title" />}
                    secondary={<FormattedMessage id="uepay_desc" />}
                  />
                </ListItem>
              }
            />
          )}
          {/* Uepay END*/}
        </RadioGroup>
      </FormControl>
      <br />
      <BootstrapButton
        type="submit"
        variant="contained"
        color="primary"
        disabled={formik.isSubmitting}
        disableElevation
      >
        <FormattedMessage id="confirm_checkout" />
      </BootstrapButton>

      <BootstrapOutlinedButton
        variant="outlined"
        color="primary"
        disabled={formik.isSubmitting}
        disableElevation
        onClick={() => cancelReturn()}
      >
        <FormattedMessage id="back" />
      </BootstrapOutlinedButton>
    </form>
  );
};

export default connect(null, paymentRedux.actions)(SelectPayChannel);

//stylesheet
const BootstrapButton = withStyles({
  root: {
    width: "100%",
    borderRadius: 0,
    backgroundColor: "#09122D",
    marginTop: 8,
    marginBottom: 8,
    "&:hover": {
      backgroundColor: "#0F2443",
    },
  },
})(Button);

//stylesheet
const BootstrapOutlinedButton = withStyles({
  root: {
    width: "100%",
    borderRadius: 0,
    marginTop: 8,
    marginBottom: 8,
    "&:hover": {},
  },
})(Button);

const MyRadio = withStyles({
  root: {
    marginRight: 16,
    color: "default",
    "&$checked": {
      color: "#09122D",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const labelStyles = makeStyles({
  root: {
    marginLeft: 0,
    justifyContent: "space-between",
    "&:hover": {},
  },
});
