const actionTypes = {
  NextStep: "[ACTION] payment next step",
  PrevStep: "[ACTION] payment previous step",
  PayChannelLoaded: "[ACTION] paymen set pay_channel value"
};

export const actions = {
  nextStep: () => ({
    type: actionTypes.NextStep
  }),

  prevStep: () => ({
    type: actionTypes.PrevStep
  }),

  fillPayChannel: (pay_channel) => ({
    type: actionTypes.PayChannelLoaded,
    payload: { pay_channel }
  })
};


const initPaymentState = {
  "step": 1,
  "pay_channel": undefined
};

export const reducer = (state = initPaymentState, action) => {
  switch (action.type) {

    case actionTypes.NextStep: {
      return { ...state, step: (state.step + 1) }
    }

    case actionTypes.PrevStep: {
      return { ...state, step: (state.step - 1) }
    }

    case actionTypes.PayChannelLoaded: {
      const { pay_channel } = action.payload;
      return {
        ...state, pay_channel: pay_channel
      }
    }

    default: {
      return state;
    }
  }
}