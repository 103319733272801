import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Menu, MenuItem } from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';

//store
import * as languageRedux from "../stores/languageRedux";

const LangMenu = (props) => {
  const language = useSelector(({ language }) => (language));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (locale) => {
    setAnchorEl(null);
    if (!!locale) props.fillLanguage(locale)
  };

  return (
    <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <LanguageIcon />
        {(language?.locale === 'zh-TW') ? (
          <span>繁</span>
        ) : (language?.locale === 'zh-CN') ? (
          <span>简</span>
        ) : (
          <span>En</span>
        )}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(null)}
      >
        <MenuItem onClick={() => handleClose('zh-TW')}>繁體中文</MenuItem>
        <MenuItem onClick={() => handleClose('zh-CN')}>简体中文</MenuItem>
        <MenuItem onClick={() => handleClose('en')}>English</MenuItem>
      </Menu>
    </>
  )

};

export default connect(null, {
  ...languageRedux.actions
})(LangMenu);

