let config = {};
if (
  window.location.hostname === "localhost" ||
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    window.location.hostname
  )
) {
  config.baseUrl = "http://" + window.location.hostname + "/mars_epay/";
  config.url = "http://" + window.location.hostname + "/mars_epay/api/";
} else {
  config.baseUrl = "http://" + window.location.hostname + "/mars_epay/";
  // config.url =
  //   process?.env?.REACT_APP_ENV === "dev"
  //     ? "https://epayapiuat.marstree.ltd/api/"
  //     : "https://epayapi.marstree.ltd/api/";
  config.url = "https://epayapi.marstree.ltd/api/";
}

export const url = config.url;
export const baseUrl = config.baseUrl;
