import axios from "axios";
import * as config from "../configs/";

axios.defaults.proxy = {
  host: config.api.url,
};

export const getOne = async (clientCredential) => {
  try {
    axios.defaults.proxy = {
      // host: (window.location.hostname === 'localhost') ? 'http://localhost' : (process?.env?.REACT_APP_ENV === 'dev') ? 'https://epayapiuat.marstree.ltd' : 'https://epayapi.marstree.ltd'
      host:
        window.location.hostname === "localhost"
          ? "http://localhost"
          : "https://epayapi.marstree.ltd",
    };
    const resp = await axios.get(
      config.api.url + "website/data?X-API-KEY=" + clientCredential
    );
    return resp;
  } catch (error) {
    console.warn("getWebsite ERROR: ", error);
    return false;
  }
};
