const actionTypes = {
  TokenLoaded: "[ACTION] token set loaded"
};

export const actions = {

  fillToken: (token) => ({
    type: actionTypes.TokenLoaded,
    payload: { token }
  })
};


const initToken = {
  "status": undefined,
  "client_credential": undefined,
  "expired_at": undefined
};

export const reducer = (state = initToken, action) => {
  switch (action.type) {

    case actionTypes.TokenLoaded: {
      const { token } = action.payload;
      return {
        ...state, ...token
      }
    }

    default: {
      return state;
    }
  }
}