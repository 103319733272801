import axios from "axios";
import * as config from "../configs";

const serialize = function (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}


export const getOne = async (params, clientCredential) => {
  try {
    const resp = await axios.get(config.api.url + 'sale_order/data?' + serialize({
      ...params,
      'X-API-KEY': clientCredential
    }));
    return resp;
  } catch (error) {
    console.warn("paySaleOrder ERROR: ", error);
    return false;
  }
}


export const pay = async (params, clientCredential) => {
  try {
    const resp = await axios.get(config.api.url + 'sale_order/pay?' + serialize({
      ...params,
      'X-API-KEY': clientCredential
    }));
    return resp;
  } catch (error) {
    console.warn("paySaleOrder ERROR: ", error);
    return false;
  }
}

