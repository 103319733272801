import { forwardRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Formik } from "formik";
import * as Yup from "yup";
import { useIntl, FormattedMessage } from "react-intl";
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Slide
} from "@mui/material";
import * as config from "../configs";

//model
import * as cardModel from "../models/cardModel";


const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CybersourceDialog = ({ open, onClose, ...prop }) => {
  const queryParams = new URLSearchParams(window.location.search);

  const [clientToken] = useState(queryParams.get('token'));
  const [txnCode] = useState(queryParams.get('txnCode'));


  //paymentInput
  const {
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    // getCVCProps
  } = usePaymentInputs();

  const intl = useIntl();

  const schema = {
    cardNumber: Yup.string().max(30)
      .required(<FormattedMessage id="isRequired" values={{ var: <FormattedMessage id="credit_card_number" /> }} />),
    expiryDate: Yup.string()
      .required(<FormattedMessage id="isRequired" values={{ var: <FormattedMessage id="expiry_date" /> }} />),
    // cvv: Yup.string().max(4)
    //   .required(<FormattedMessage id="isRequired" values={{ var: <FormattedMessage id="cvc" /> }} />),
    accountName: Yup.string().max(255)
      .required(<FormattedMessage id="isRequired" values={{ var: <FormattedMessage id="account_name" /> }} />),
  };


  const handleSubmit = async (values, { setSubmitting, setStatus, setErrors }) => {
    console.log('values:', values);
    const info = btoa(JSON.stringify(values) + 'mars_epay');
    const hash = uuidv4();
    setSubmitting(true);
    try {
      const resp = await cardModel.add({
        hash: hash,
        info: btoa(info + '0hjditmcC$' + hash)
      }, clientToken);
      const { data } = resp;
      window.top.location.href = config.api.url
        + 'sale_order/pay?code=' + txnCode
        + '&payChannel=boc_cybersource'
        + '&X-API-KEY=' + clientToken
        + '&hash=' + data?.hash

    } catch (error) {
      setErrors(error);
      console.log('error', error);
    } finally {
      setSubmitting(false);
    }
  }


  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      keepMounted
      TransitionComponent={Transition}
      fullWidth
    >

      <DialogTitle>{intl.formatMessage({ id: 'please_insert_card_information' })}</DialogTitle>
      <Formik
        autoComplete="off"
        enableReinitialize
        initialValues={{
          cardNumber: '',
          expiryDate: '',
          cvv: '',
          accountName: ''
        }}
        validationSchema={
          Yup.object().shape(schema)
        }
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          status,
          values,
          setFieldValue
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={8} sm={8}>
                  <TextField
                    {...getCardNumberProps({
                      refKey: "inputRef",
                      onChange: handleChange
                    })}
                    fullWidth
                    type="tel"
                    label={intl.formatMessage({ id: "credit_card_number" })}
                    name="cardNumber"
                    variant="filled"
                    error={Boolean(touched.cardNumber && errors.cardNumber)}
                    helperText={touched.cardNumber && errors.cardNumber}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <svg {...getCardImageProps({ images })} />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    {...getExpiryDateProps({
                      refKey: "inputRef",
                      onChange: handleChange
                    })}
                    inputRef={getExpiryDateProps().ref}
                    fullWidth
                    type="tel"
                    label={intl.formatMessage({ id: "expiry_date" })}
                    name="expiryDate"
                    variant="filled"
                    error={
                      (errors.expiryDate && touched.expiryDate)
                    }
                    helperText={
                      (errors.expiryDate &&
                        touched.expiryDate)
                    }
                  />
                </Grid>
                {/* <Grid item xs={6} sm={6}>
                  <TextField
                    {...getCVCProps({
                      refKey: "inputRef",
                      onChange: handleChange,
                      onBlur: handleBlur
                    })}
                    inputRef={getCVCProps().ref}
                    fullWidth
                    type="tel"
                    label={intl.formatMessage({ id: "cvc" })}
                    name="cvv"
                    variant="filled"
                    error={
                      (errors.cvc && touched.cvc)
                    }
                    helperText={
                      (errors.cvc && touched.cvc)
                    }
                  />
                </Grid> */}
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label={intl.formatMessage({ id: "account_name" })}
                    name="accountName"
                    variant="filled"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.accountName && errors.accountName)}
                    helperText={touched.accountName && errors.accountName}
                  />
                </Grid>
              </Grid>

            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                {intl.formatMessage({ id: 'cancelText' })}
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={!!isSubmitting}
              >
                {(isSubmitting) ? (
                  <FormattedMessage id="loadingText" />
                ) : (
                  <FormattedMessage id="submitText" />
                )}
              </Button>

            </DialogActions>
          </form>

        )}

      </Formik >

    </Dialog >
  );
}

export default CybersourceDialog